.receipt-selector-container {
  width: 100%;
  height: 550px; /* Increased height to accommodate taller receipts */
  padding: 1rem;
  background: var(--background-color);
  border-top: 1px solid var(--text-color);
}

.receipt-selector-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.receipt-selector-header {
  flex: 0 0 auto;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.receipt-selector-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.receipt-selector-hint {
  color: var(--text-color);
  opacity: 0.7;
  font-size: 0.875rem;
}

.receipt-selector-grid {
  flex: 1;
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  align-items: flex-start; /* Align items to the top */
  min-height: 280px; /* Ensure enough height for receipts */
}

.receipt-item {
  flex: 0 0 auto;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 4px;
  transition: all 0.2s ease;
  pointer-events: auto !important;
  max-height: 100%; /* Ensure receipt doesn't overflow container */
  display: flex; /* Added to ensure proper alignment */
  align-items: center; /* Center content vertically */
}

.receipt-item:hover {
  border-color: var(--button-background);
}

.receipt-item.selected {
  border-color: var(--button-background);
  background-color: rgba(var(--text-color-rgb), 0.05);
}

.receipt-item.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 150px;
  background-color: rgba(var(--text-color-rgb), 0.05);
  border-radius: 4px;
}

/* Update pointer events selectors */
.receipt-selector-container,
.receipt-selector-content,
.receipt-selector-grid {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.receipt-item svg,
.receipt-item svg *,
.receipt-item text,
.receipt-item rect {
  pointer-events: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pagination-button {
  background: transparent;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.pagination-button:hover:not(:disabled) {
  background: var(--text-color);
  color: var(--background-color);
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.receipt-count {
  font-size: 0.875rem;
  color: var(--text-color);
  opacity: 0.7;
}

.receipt-loading-spinner {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.receipt-loading-spinner .spinner {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 4px solid var(--text-color);
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--text-color) transparent transparent transparent;
} 
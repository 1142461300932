.tag-validation-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  width: 100%;
}

.chart-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.chart-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tag-label {
  min-width: 90px;
  text-align: right;
  color: var(--text-color);
}

.bar-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  flex-grow: 1;
  min-width: 0;
  width: 100%;
  overflow: visible;
}

.total-count {
  min-width: 40px;
  color: var(--text-color);
}

.chart-legend {
  display: flex;
  gap: 24px;
  justify-content: center;
  /* margin-top: 24px; */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.chart-legend.show {
  opacity: 1;
}

.legend-group {
  display: flex;
  gap: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding-left: 24px;  /* Space for the swatch */
}

.legend-swatch {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.legend-swatch.filled {
  background-color: var(--text-color);
}

.legend-swatch.outlined {
  background-color: var(--background-color);
  border: 2px solid var(--text-color);
  box-sizing: border-box;
}

/* Inherit font styles from body */
.tag-validation-container text,
.tag-label {
  font: inherit;
} 

.chart-row {
  opacity: 0;
  /* transform: translateY(20px); */
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.chart-row.visible {
  opacity: 1;
  /* transform: translateY(0); */
}

@media (max-width: 600px) {
  .chart-legend {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 32px;  /* row-gap column-gap */
    width: fit-content;
    margin: 0 auto;  /* Center horizontally */
  }

  .legend-group {
    display: contents;  /* This removes the group container from layout */
  }

  .legend-item {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    padding-left: 24px;
  }
}